import '../styles/home.css';
import Header from "../components/header";
import MainView from "../components/home/main_view";
import ReadThis from "../components/home/read_this";
import DlOurApp from "../components/home/dl_our_app";
import WeekQuestion from "../components/home/week_question";
import Map from "../components/home/map"
import JoinParty from "../components/home/join_party"
import Footer from "../components/footer"
import 'animate.css';

function Home() {
  return (
    <div>
        <Header />
        <div className='body main-body'>
            <MainView />
            <ReadThis />
            {/* <DlOurApp />
            <WeekQuestion /> */}
            <Map />
            <JoinParty />
            <Footer />
        </div>
    </div>
  );
}

export default Home;