import {
  BrowserRouter,
  Routes,
  Route,
} from 'react-router-dom';

import './styles/App.css';

import ScrollToTop from "./components/scroll_top"
import Home from "./pages/home"
import YourFederation from "./pages/your_federation"
import OurIdeas from "./pages/our_ideas"
import WhoWeAre from "./pages/who_we_are"
import Subscriber from "./pages/subscriber"
import Contact from "./pages/contact"
import TextFounding from "./pages/text-founding"
import ActiveAccount from "./pages/user/activate_account"
import Privacy from "./pages/privacy"
import Representative from "./pages/representative"
import IdeaMore from "./pages/idea-more"
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react';

function App() {
  useEffect(() => {
    AOS.init();
  })

  return (
      <BrowserRouter>
        <ScrollToTop />
          <div className="App">
            <Routes>
              <Route path="/" element={<Home/>}></Route>
              <Route path="/your_federation" element={<YourFederation/>}></Route>
              <Route path="/our_ideas" element={<OurIdeas/>}></Route>
              <Route path="/who_we_are" element={<WhoWeAre/>}></Route>
              <Route path="/subscriber" element={<Subscriber/>}></Route>
              <Route path="/contact" element={<Contact/>}></Route>
              <Route path="/text_founding" element={<TextFounding/>}></Route>
              <Route path="/privacy" element={<Privacy/>}></Route>
              <Route path="/representative" element={<Representative/>}></Route>
              <Route path="/idea_more" element={<IdeaMore/>}></Route>
              <Route exact path="/user/activate_account" element={<ActiveAccount/>}></Route>
            </Routes>
            <Subscriber />
          </div>
      </BrowserRouter>
  );
}

export default App;
