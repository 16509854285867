import '../styles/who_we_are.css';
import Header from "../components/header";
import Footer from "../components/footer"
import president from "../assets/president.jpeg"
import Grid from "../components/text-founding/container"
import File from "../assets/new_file.png"
import video from "../assets/work.mp4"
import videoPoster from "../assets/work-poster.jpg"
import { useEffect } from 'react';
import ourValues from "../assets/our_values.jpg"
import { Link } from 'react-router-dom';

function WhoWeAre() {
  useEffect(() => {
    const items = document.querySelectorAll(".accordion button");

    function toggleAccordion() {
      const itemToggle = this.getAttribute('aria-expanded');

      for (var i = 0; i < items.length; i++) {
        items[i].setAttribute('aria-expanded', 'false');
      }

      if (itemToggle === 'false') {
        this.setAttribute('aria-expanded', 'true');
      }
    }

    items.forEach(item => item.addEventListener('click', toggleAccordion));
  })

  return (
    <div>
        <Header />
        <div className='body who-we-are-background'>
          <div className='about-view'>
            <div className='about-view-content' data-aos="fade-up">
                <video src={video} autoplay="1" muted="1" loop="1" playsinline="1" poster={videoPoster} controlslist="nodownload" id="u_0_k_NA"></video>
                <div className='about-filter-video'></div>
                <div className='about-view-content-center'>
                  <span>Discover</span>
                  <h1>Find out who we are and what our mission</h1>
                </div>

                <div class="scroll-downs">
                  <div class="mousey">
                    <div class="scroller"></div>
                  </div>
                </div>
            </div>
            <div className='who-we-are-dna' data-aos="fade-up">
              <h2>Because caring is part of our DNA and the success of our country is our priority, we put all our energy into making sure we have a good future</h2>
            </div>
            <div className='about-representer' data-aos="fade-up">
              <div className='about-representer-bleu-frame'></div>
              <div className='about-president'>
                <div className='about-president-image'>
                  <img src={president} alt="president" />
                </div>
                <div className='about-president-description'>
                  <h2>A word from our representative</h2>
                  <div class="blockquote">
                    <div className='blockquote-frame'>
                      <img src={require('../assets/quote-open.svg').default} alt="quate" className='quote-open' />
                      <p>As the Chairman of the Chechen Democratic Party, I firmly believe that democracy is not just a political concept; it is the essence of a society that empowers its citizens, upholds human rights, and nurtures a culture of inclusivity and progress. In Chechnya, our journey towards a democratic society is not just a choice; it is a necessity if we wish to pave the way for a brighter future.</p>
                      <img src={require('../assets/quote-close.svg').default} alt="quate" className='quote-close' />
                    </div>
                    <span className='margin-top-30'>Huseyn ISKHANOV</span>
                  </div>
                  <Link to="/representative"><button className='button-president'>Узнать больше</button></Link>
                </div>
              </div>
            </div>
            <div className='about-frame' data-aos="fade-up">
              <h2 className='expl-about-h2'>We are guided by <span className="color-blue-radiant">our values</span></h2>
              <p>By focusing on core values such as ethics, integrity and respect for human rights, we can make decisions that have a positive impact on people and the planet.</p>
                <div className='frame-about-expl'>
                <img src={ourValues} alt="our_values" className='our-values-first' />
                  <div class="accordion">
                    <div class="accordion-item">
                      <button id="accordion-button-1" aria-expanded="false"><span class="accordion-title">Speach for basic human rights</span><span class="icon" aria-hidden="true"></span></button>
                      <div class="accordion-content">
                        <p>In a democratic society, one of the main pillars is the protection and promotion of basic human rights. These rights are universal and inalienable, and include such things as freedom of expression, freedom of thought, freedom of religion and freedom of peaceful assembly. Democratic governments are obligated to protect these rights and to respect them themselves.</p>
                      </div>
                    </div>
                    <div class="accordion-item">
                      <button id="accordion-button-2" aria-expanded="false"><span class="accordion-title">Transparency</span><span class="icon" aria-hidden="true"></span></button>
                      <div class="accordion-content">
                        <p>Transparency is a fundamental pillar of democracy. In a democratic society, citizens have the right to know what their leaders are doing and how they are making decisions. This allows citizens to hold leaders accountable for their actions and to ensure that their voices are heard.</p>
                      </div>
                    </div>
                    <div class="accordion-item">
                      <button id="accordion-button-3" aria-expanded="false"><span class="accordion-title">Honesty</span><span class="icon" aria-hidden="true"></span></button>
                      <div class="accordion-content">
                        <p>Honesty is one of the fundamental pillars of democracy. It is essential to maintaining the people's trust in government institutions and to ensuring that elected leaders live up to the promises they made during their campaigns. Honesty begins with true representation of the people's voices. Elections must be fair and transparent, with clear and equitable rules that apply to all candidates.</p>
                      </div>
                    </div>
                    <div class="accordion-item">
                      <button id="accordion-button-4" aria-expanded="false"><span class="accordion-title">Freedom of speach</span><span class="icon" aria-hidden="true"></span></button>
                      <div class="accordion-content">
                        <p>Freedom of expression is a fundamental pillar of democracy. In a democratic society, every citizen has the right to express himself or herself freely without fear of persecution or reprisal by the authorities. Freedom of expression guarantees the ability of citizens to share their ideas and opinions on political, economic, social and cultural issues.</p>
                      </div>
                    </div>
                  </div>
                  <img src={ourValues} alt="our_values" className='our-values-second' />
                </div>
              {/* <p className='expl-about-p'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla ornare, mauris eu accumsan auctor, libero erat luctus sem, et porttitor libero diam eget massa. Vivamus dictum, orci sit amet<br /><br />finibus faucibus, tellus tellus aliquam orci, et pellentesque eros dui ac ipsum. Etiam sit amet iaculis eros. In ornare tristique lorem Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla ornare, mauris eu accumsan auctor, libero erat luctus sem, et porttitor libero diam eget massa. Vivamus dictum, orci sit amet finibus faucibus, tellus tellus aliquam orci, et pellentesque eros dui ac ipsum. Etiam sit amet iaculis eros. In ornare tristique lorem Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla ornare, mauris eu accumsan auctor, libero erat luctus sem, et porttitor libero diam eget massa. Vivamus dictum, orci sit amet finibus faucibus, tellus tellus aliquam orci, et pellentesque eros dui ac ipsum. Etiam sit amet iaculis eros. In ornare tristique lorem Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla ornare,<br /><br />mauris eu accumsan auctor, libero erat luctus sem, et porttitor libero diam eget massa. Vivamus dictum, orci sit amet finibus faucibus, tellus tellus aliquam orci, et pellentesque eros dui ac ipsum. Etiam sit amet iaculis eros. In ornare tristique lorem</p> */}
            </div>
            <div className="about-text-founding" data-aos="fade-up">
              <h2>Discover our <span className="color-blue-radiant">founding texts</span></h2>
              <p>These may include bylaws, charters, policy statements or other documents that set out the values, goals and guiding principles of the organization.</p>
              <div className='files-link-text-founding'>
                    <Grid
                        image={File}
                        link={"http://localhost:3000/files/text-fondateur.pdf"}
                        name={"The Charter of the Chechen Democratic Party"}
                        color={"transparent"}
                        colorText={"black"}
                        statusFile={false}
                        text={"The Charter of the Chechen Democratic Party is a founding document that defines the principles and values of the party. It was written to guide the actions of the party in its mission to promote democracy and human rights in Chechnya."}
                    />
                    <Grid
                        image={File}
                        link={"http://localhost:3000/files/text-fondateur.pdf"}
                        name={"Rules of the movement procedure"}
                        color={"transparent"}
                        colorText={"black"}
                        statusFile={true}
                        text={"The rules of motion procedure are a set of guidelines set forth to guide the members of a political party or organization in their collective decision making process."}
                    />
                </div>
            </div>
          </div>
          <Footer />
        </div>
    </div>
  );
}

export default WhoWeAre;