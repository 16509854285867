import '../styles/our_ideas.css';
import Header from "../components/header";
import Footer from "../components/footer"
import democracy from "../assets/democracy.png"
import protectorat from "../assets/protectorat.png"
import deso from "../assets/deso.png"
import consti from "../assets/consti.png"
import { Link } from 'react-router-dom';

function OurIdeas() {
  return (
    <div>
        <Header />
        <div className='body our-ideas-background'>
            <div className='our-ideas-content'>
              <h1>Our <span className="color-blue-radiant">ideas</span></h1>
              <p className="description-your-federation-view">The meeting of ideas, discover all our ideas for Chechnya, so that we can become a free country.</p>
            </div>
            <div className='our-ideas-body'>
              <div className='our-ideas-body-splace-beetween margin-bottom-150 margin-top-100' data-aos="fade-up">
                <div className='our-ideas-column2 only-mobile'>
                  <img src={democracy} />
                </div>
                <div className='our-ideas-column our-ideas-column-left'>
                  <h1>Democracy</h1>
                  <p>Much has been written about the democratism of Chechen society: by historians, military men, travelers of the Middle Ages and the 16th-19th centuries, whom the propaganda of the Russian empire of evil presents to all as religious fanatics and villains.  Here are brief comments of these witnesses who encountered these people in different centuries: Chechens are the main population of Chechnya.</p>
                  <Link to="/idea_more?w=1"><button>Learn More <img src={require('../assets/right_white.svg').default} alt="right_arrow" /></button></Link>
                </div>
                <div className='our-ideas-column2 only-pc'>
                    <img src={democracy} />
                </div>
              </div>
              <div className='our-ideas-body-splace-beetween space30 margin-bottom-150' data-aos="fade-up">
                <div className='our-ideas-column2'>
                  <img src={deso} />
                </div>
                <div className='our-ideas-column our-ideas-column-left'>
                  <h1>De-occupancy</h1>
                  <p>Deoccupation. As a result of two brutal Russian-Chechen wars that lasted a total of ten years, the territory of the Chechen Republic of Ichkeria was completely occupied by Russian troops. Despite the need for troops for the war with Ukraine, the hundred-thousand contingent in the Chechen Republic is not even going to be redeployed.</p>
                  <Link to="/idea_more?w=2"><button>Learn More <img src={require('../assets/right_white.svg').default} alt="right_arrow" /></button></Link>
                </div>
              </div>
              <div className='our-ideas-body-splace-beetween space30 margin-bottom-150' data-aos="fade-up">
                <div className='our-ideas-column2 only-mobile'>
                  <img src={consti} />
                </div>
                <div className='our-ideas-column our-ideas-column-left'>
                  <h1>1992 Constitution</h1>
                  <p>The Constitution of the Chechen Republic came into force on March 12, 1992. The day of adoption of the Constitution of the Chechen Republic March 12 is declared a national holiday.</p>
                  <Link to="/idea_more?w=3"><button>Learn More <img src={require('../assets/right_white.svg').default} alt="right_arrow" /></button></Link>
                </div>
                <div className='our-ideas-column2 only-pc'>
                  <img src={consti} />
                </div>
              </div>
              <div className='our-ideas-body-splace-beetween space30 margin-bottom-50 mobile-margin-bottom-null' data-aos="fade-up">
                <div className='our-ideas-column2'>
                  <img src={protectorat} />
                </div>
                <div className='our-ideas-column our-ideas-column-left'>
                  <h1>Protectorate</h1>
                  <p>The Chechen people need support in the restoration of democratic order in the Republic, through a transitional period of several years of International trusteeship, which will lead to the realization of legitimate aspirations in Chechnya and allow the Chechen society to recover from the catastrophe of recent decades.</p>
                  <Link to="/idea_more?w=4"><button>Learn More <img src={require('../assets/right_white.svg').default} alt="right_arrow" /></button></Link>
                </div>
              </div>
            </div>
          <Footer />
        </div>
    </div>
  );
}

export default OurIdeas;