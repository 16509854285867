import '../styles/contact.css';
import Header from "../components/header";
import Footer from "../components/footer"

function Contact() {
  return (
    <div>
        <Header />
        <div className='body'>
            <div className='contact'>
                <div className='contact-content'>
                    <div className='contact-left'>
                      <h2>Hello, how can we <span className="color-blue-radiant animate__fadeInUp">help you</span>?</h2>
                      <p className='contact-description'>Do you have questions or want to report a problem? We're here to help.</p>
                      <div className='contact-flex-box'>
                        <div className='contact-flex-box-frame'>
                          <h3>Join the movement</h3>
                          <p>Join the movement, contact us by e-mail at <strong>marsho@chechendemocraticparty.com</strong></p>
                        </div>
                        <div className='contact-flex-box-frame space-only-mobile'>
                          <h3>Press</h3>
                          <p>If you are a journalist, contact us by e-mail at <strong>press@chechendemocraticparty.com</strong></p>
                        </div>
                        <div className='contact-flex-box-frame space-frame'>
                          <h3>Political</h3>
                          <p>If you are a politic, contact us by e-mail at <strong>politics@chechendemocraticparty.com</strong></p>
                        </div>
                      </div>
                    </div>
                </div>
            </div>
        <Footer />
        </div>
    </div>
  );
}

export default Contact;