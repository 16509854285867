import { Link } from 'react-router-dom';
import '../../styles/text-founding.css';
import File from "../../assets/new_file.png"

function Grid({link, name, image, color, colorText, statusFile, text}) {
    return (
        <Link to={link} target={"_blank"}>
            <div className='grid-text-founding' style={{background: color}}>
                <div className='grid-text-founding-left'>
                    <img src={File} alt='image' />
                    <div className='description-grid-text-founding'>
                        <h3 style={{color: colorText}}>{name}</h3>
                        <p style={{color: colorText}}>{text}</p>
                    </div>
                </div>
                <div className='grid-text-founding-right'>
                    <button>Download</button>
                    <p>{statusFile ? "This file can evolve" : ""}</p>
                </div>
            </div>
        </Link>
    );
}

export default Grid;