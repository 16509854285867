import '../styles/subscriber.css';
import hello from "../assets/hello.png"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function Subscriber() {
  const close = () => {
    const subscriber = document.getElementsByClassName("subscriber");

    document.body.style.overflow = "scroll"
    subscriber[0].style.display = "none"
  }

  return (
    <div className='subscriber'>
        <div className='background-image-subscriber'>
            <div className='subscriber-close' onClick={close}>
              <img src={require('../assets/close.svg').default} alt="close" />
            </div>
            <div className='subscriber-view-content'>
                <div className='subscriber-view-content-left'>
                  <div className='subscriber-view-content-left-image'>
                    <img src={hello} alt="hello" />
                  </div>
                  <h2>Присоединяйтесь с <span className="color-blue-radiant">уверенностью</span></h2>
                  <p className="description-subscriber-view">Все запрашиваемые нами данные будут анонимными и зашифрованными, они будут обнародованы только по вашему запросу.</p>
                  <ul>
                    <li>
                      <img src={require('../assets/form.svg').default} alt="form" />
                      <h6>Шаг 1: Заполните форму</h6>
                      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                    </li>
                    <li>
                      <img src={require('../assets/group.svg').default} alt="form" />
                      <h6>Шаг 2. Кто-то из нашей команды свяжется с вами</h6>
                      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                    </li>
                    <li>
                      <img src={require('../assets/welcome.svg').default} alt="form" />
                      <h6>Шаг 3. Добро пожаловать в группу</h6>
                      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                    </li>
                  </ul>
                </div>
                <div className='subscriber-view-content-right'>
                  <form method="POST" action="">
                    <div className='row-subscriber-form'>
                      <div className='column-subscriber-form'>
                        <label for="sex-select">Цивилизованность</label>
                        <select name="pets" id="sex-select">
                          <option value="dog">Мужчина</option>
                          <option value="cat">Женщина</option>
                        </select>
                      </div>

                      <div className='column-subscriber-form'>
                        <label for="country-select">Страна проживания</label>
                        <select name="pets" id="country-select">
                          <option value="dog">Homme</option>
                          <option value="cat">Femme</option>
                        </select>
                      </div>
                    </div>
                    <div className='row-subscriber-form'>
                      <div className='column-subscriber-form'>
                        <label for="sex-select">Имя</label>
                        <input type="text" placeholder='John' />
                      </div>
                      <div className='column-subscriber-form'>
                        <label for="sex-select">Фамилия</label>
                        <input type="text" placeholder="Doe" />
                      </div>
                    </div>

                    <label for="country-select">Дата рождения</label>

                    <div className='row-subscriber-form'>
                      <div className='column-subscriber-form-2'>
                        <label for="sex-select">День</label>
                        <select name="pets" id="sex-select">
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                          <option value="6">6</option>
                          <option value="7">7</option>
                          <option value="8">8</option>
                          <option value="9">9</option>
                          <option value="10">10</option>
                          <option value="11">11</option>
                          <option value="12">12</option>
                          <option value="13">13</option>
                          <option value="14">14</option>
                          <option value="15">15</option>
                          <option value="16">16</option>
                          <option value="17">17</option>
                          <option value="18">18</option>
                          <option value="19">19</option>
                          <option value="20">20</option>
                          <option value="21">21</option>
                          <option value="22">22</option>
                          <option value="23">23</option>
                          <option value="24">24</option>
                          <option value="25">25</option>
                          <option value="26">26</option>
                          <option value="27">27</option>
                          <option value="28">28</option>
                          <option value="29">29</option>
                          <option value="30">30</option>
                          <option value="31">31</option>
                        </select>
                      </div>

                      <div className='column-subscriber-form-2'>
                        <label for="country-select">Месяц</label>
                        <select name="pets" id="country-select">
                          <option value="1">Январь</option>
                          <option value="2">Февраль</option>
                          <option value="3">Март</option>
                          <option value="4">Апрель</option>
                          <option value="5">Май</option>
                          <option value="6">Июнь</option>
                          <option value="7">Июль</option>
                          <option value="8">Август</option>
                          <option value="9">Сентябрь</option>
                          <option value="10">Октябрь</option>
                          <option value="11">Ноябрь</option>
                          <option value="12">Декабрь</option>
                        </select>
                      </div>
                      <div className='column-subscriber-form-2'>
                        <label for="country-select">Год</label>
                        <select name="pets" id="country-select">
                          <option value="1923">1923</option>
                          <option value="1924">1924</option>
                          <option value="1925">1925</option>
                          <option value="1926">1926</option>
                          <option value="1927">1927</option>
                          <option value="1928">1928</option>
                          <option value="1929">1929</option>
                          <option value="1930">1930</option>
                          <option value="1931">1931</option>
                          <option value="1932">1932</option>
                          <option value="1933">1933</option>
                          <option value="1934">1934</option>
                          <option value="1935">1935</option>
                          <option value="1936">1936</option>
                          <option value="1937">1937</option>
                          <option value="1938">1938</option>
                          <option value="1939">1939</option>
                          <option value="1940">1940</option>
                          <option value="1941">1941</option>
                          <option value="1942">1942</option>
                          <option value="1943">1943</option>
                          <option value="1944">1944</option>
                          <option value="1945">1945</option>
                          <option value="1946">1946</option>
                          <option value="1947">1947</option>
                          <option value="1948">1948</option>
                          <option value="1949">1949</option>
                          <option value="1950">1950</option>
                          <option value="1951">1951</option>
                          <option value="1952">1952</option>
                          <option value="1953">1953</option>
                          <option value="1954">1954</option>
                          <option value="1955">1955</option>
                          <option value="1956">1956</option>
                          <option value="1957">1957</option>
                          <option value="1958">1958</option>
                          <option value="1959">1959</option>
                          <option value="1960">1960</option>
                          <option value="1961">1961</option>
                          <option value="1962">1962</option>
                          <option value="1963">1963</option>
                          <option value="1964">1964</option>
                          <option value="1965">1965</option>
                          <option value="1966">1966</option>
                          <option value="1967">1967</option>
                          <option value="1968">1968</option>
                          <option value="1969">1969</option>
                          <option value="1970">1970</option>
                          <option value="1971">1971</option>
                          <option value="1972">1972</option>
                          <option value="1973">1973</option>
                          <option value="1974">1974</option>
                          <option value="1975">1975</option>
                          <option value="1976">1976</option>
                          <option value="1977">1977</option>
                          <option value="1978">1978</option>
                          <option value="1979">1979</option>
                          <option value="1980">1980</option>
                          <option value="1981">1981</option>
                          <option value="1982">1982</option>
                          <option value="1983">1983</option>
                          <option value="1984">1984</option>
                          <option value="1985">1985</option>
                          <option value="1986">1986</option>
                          <option value="1987">1987</option>
                          <option value="1988">1988</option>
                          <option value="1989">1989</option>
                          <option value="1990">1990</option>
                          <option value="1991">1991</option>
                          <option value="1992">1992</option>
                          <option value="1993">1993</option>
                          <option value="1994">1994</option>
                          <option value="1995">1995</option>
                          <option value="1996">1996</option>
                          <option value="1997">1997</option>
                          <option value="1998">1998</option>
                          <option value="1999">1999</option>
                          <option value="2000">2000</option>
                          <option value="2001">2001</option>
                          <option value="2002">2002</option>
                          <option value="2003">2003</option>
                          <option value="2004">2004</option>
                          <option value="2005">2005</option>
                          <option value="2006">2006</option>
                          <option value="2007">2007</option>
                          <option value="2008">2008</option>
                          <option value="2009">2009</option>
                          <option value="2010">2010</option>
                          <option value="2011">2011</option>
                          <option value="2012">2012</option>
                          <option value="2013">2013</option>
                          <option value="2014">2014</option>
                          <option value="2015">2015</option>
                          <option value="2016">2016</option>
                          <option value="2017">2017</option>
                          <option value="2018">2018</option>
                          <option value="2019">2019</option>
                          <option value="2020">2020</option>
                          <option value="2021">2021</option>
                          <option value="2022">2022</option>
                          <option value="2023">2023</option>
                        </select>
                      </div>
                    </div>

                    <label for="sex-select">Электронная почта</label>
                    <input type="text" placeholder="john.doe@chdp.com" />

                    <label for="sex-select">Мобильный телефон</label>
                    <input type="text" placeholder='+00000000000' />
                    <button className='form-button-subscriber'>Отправить</button>
                  </form>
                </div>
            </div>
        </div>
    </div>
  );
}

export default Subscriber;