import "../../styles/home.css"
import map from "../../assets/map.png"

function Map() {
  return (
    <div className="map-view" data-aos="fade-up">
      <div className="map-view-content">
          <h2>Wherever you are, <span className="color-blue-radiant">come and help</span> create our new Chechnya.</h2>
          <p>Our members for the democratic part are all over the world, do not wait any longer, your help is very important for us.</p>
          <img src={map} alt="map" />
      </div>
    </div>
  );
}

export default Map;