import { initializeApp } from "firebase/app";
import { getFirestore, getDocs, collection, query, where, updateDoc, doc } from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import React, { useEffect, useState } from "react";
import '../../styles/user/user.css';
import Header from "../../components/header";
import { SwapSpinner } from "react-spinners-kit";

const firebaseConfig = {
    apiKey: "AIzaSyCtSIbThgUlt9dr6Wcw9rAT8q4Mzn6o5X4",
    authDomain: "chdpqa.firebaseapp.com",
    projectId: "chdpqa",
    storageBucket: "chdpqa.appspot.com",
    messagingSenderId: "229005004837",
    appId: "1:229005004837:web:f3356ca134c4c4a28a2fcc",
    measurementId: "G-5YT3FSWCVG"
};

function ActiveAccount() {
    const [status, setStatus] = useState(false);
    const [state, setState] = useState('');

    const fetchActivateAccount = async () => {
        if (status === false) {
            setStatus(true)
            const app = initializeApp(firebaseConfig);
            const db = getFirestore(app);
            const queryParams = new URLSearchParams(window.location.search);
            const token = queryParams.get("token");

            if (token !== null && token !== undefined) {
                const collection_ref_tasks = collection(db, "chdp_tasks")
                const q_tasks = query(collection_ref_tasks, where("chdp_token", "==", token))
                const doc_refs_tasks = await getDocs(q_tasks);

                if (doc_refs_tasks.docs[0] === null || doc_refs_tasks.docs[0] === undefined) {
                    setState("TOKEN_NOT_VALID");
                    return;
                }

                const data = doc_refs_tasks.docs[0].data();
                const user = data.chdp_owner;

                if (data.chdp_treated !== null && data.chdp_treated !== undefined && data.chdp_treated === true) {
                    setState("ALREADY_VALIDATE");
                    return;
                }

                if (user !== null || user !== undefined) {
                    const userDoc = doc(db, "chdp_users", user);

                    await updateDoc(userDoc, {
                        chdp_account_verify: true,
                    });

                    await updateDoc(doc(db, "chdp_tasks", doc_refs_tasks.docs[0].id), {
                        chdp_treated: true,
                    });

                    const functions  = getFunctions(app);

                    const callableValidateAccount = httpsCallable(functions, "validate_account");

                    callableValidateAccount({uid: user});

                    setState("VALIDATE");
                    return;
                }
            } else {
                setState("ERROR_PARAMS");
                return;
            }
        }
    }

    useEffect(() => {
        fetchActivateAccount();
    })

    return (
      <div>
        <Header />
        <div className="user-center">
            <div className="user-content">
                {state === "" ? <SwapSpinner size={30} color="#000" loading={true} className="center-loader" /> : <img src={state === "ERROR_PARAMS" || state === "TOKEN_NOT_VALID" ? require('../../assets/error.png') : state === "ALREADY_VALIDATE" ? require('../../assets/info.svg').default : require('../../assets/check.svg').default} alt="img-user" width="100" height='100' className="image-user" />}
                <p className="description-user">{state === "VALIDATE" ?
                    "Ваша учетная запись была подтверждена, теперь вы можете войти в систему" :
                state === "ERROR_PARAMS" || state === "TOKEN_NOT_VALID" ?
                    "Ошибка, похоже, что произошла ошибка, если ошибка сохраняется, пожалуйста, свяжитесь с нашей службой поддержки по электронной почте: contact@chechendemocraticparty.com."
                    : state === "ALREADY_VALIDATE" ? "Ваша учетная запись уже подтверждена, если у вас возникли какие-либо ошибки, пожалуйста, напишите нам по адресу contact@chechendemocraticparty.com." : ""}</p>
            </div>
        </div>
      </div>
    );
  }

export default ActiveAccount;