import {Link} from 'react-router-dom';
import '../styles/header.css';
import logo from "../assets/logo.png";
import React, { useState, useEffect } from "react";

function Header() {
  const [hamburgerOpen, setHamburgerOpen] = useState(false);

  const modalSubscribe = () => {
    const subscriber = document.getElementsByClassName("subscriber");
    document.body.style.overflow = "hidden"
    subscriber[0].style.display = "block"
  }

  const toggleHamburger = () => {
    setHamburgerOpen(!hamburgerOpen)

    const burger = document.getElementsByClassName("burger1");

    if (hamburgerOpen === false) {
      burger[0].style.backgroundColor = "white";
      burger[1].style.backgroundColor = "white";
      burger[2].style.backgroundColor = "white";

      burger[0].style.transform = "rotate(45deg)";
      burger[1].style.transform = "translateX(100%)";
      burger[2].style.transform = "rotate(-45deg)";

      burger[1].style.opacity = "0";
    } else {
      burger[0].style.backgroundColor = "black";
      burger[1].style.backgroundColor = "black";
      burger[2].style.backgroundColor = "black";

      burger[0].style.transform = "rotate(0)";
      burger[1].style.transform = "translateX(0)";
      burger[2].style.transform = "rotate(0)";

      burger[1].style.opacity = "1";
    }
  }

  const closeNavigation = () => {
    const navigation = document.getElementsByClassName("navigation");
    const burger = document.getElementsByClassName("burger");

    navigation[0].style.display = "none";
    burger[0].style.backgroundColor = "black";
    burger[1].style.backgroundColor = "black";
    burger[2].style.backgroundColor = "black";

    burger[0].style.transform = "rotate(0)";
    burger[1].style.transform = "translateX(0)";
    burger[2].style.transform = "rotate(0)";

    burger[1].style.opacity = "1";

    setHamburgerOpen(false)
  }

  const scroll = () => {
    const header = document.getElementsByClassName("header");
    const lastKnownScrollPosition = window.scrollY;

    if (lastKnownScrollPosition === 0) {
      header[0].style.boxShadow = "0px 0px 0px transparent";
    } else {
      header[0].style.boxShadow = "0px 10px 20px rgba(0, 0, 0, 0.1)";
    }
  }

  useEffect(() => {
    window.addEventListener('resize', closeNavigation)
    window.addEventListener('scroll', scroll)
  })

  return (
    <div>
      <header className="header">
        <div className='header-center'>
          <div className='left-elements'>
            <Link to="/" className='logo-href'><img src={logo} alt="logo" /></Link>
            <ul>
              <li><Link to="/">Home</Link></li>
              <li><Link to="/who_we_are">Who we are</Link></li>
              <li><Link to="/our_ideas">Our ideas</Link></li>
              <li><Link to="/your_federation">Your subsidiary</Link></li>
            </ul>
          </div>
        </div>
      </header>
      <div className='hamburger' onClick={toggleHamburger}>
        <div className='burger burger1'></div>
        <div className='burger burger1'></div>
        <div className='burger burger1'></div>
      </div>
      <div className='navigation' onClick={closeNavigation} style={{display: hamburgerOpen ? "inline": "none"}}>
        <div className='navigation-content'>
          <ul>
            <li><Link to="/">Home</Link></li>
            <li><Link to="/who_we_are">Who we are</Link></li>
            <li><Link to="/our_ideas">Our ideas</Link></li>
            <li><Link to="/your_federation">Your subsidiary</Link></li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Header;