import {Link} from 'react-router-dom';
import "../styles/footer.css"
import logo from "../assets/logo.png"
import tw from "../assets/tw.png"
import fb from "../assets/fb.png"
import ig from "../assets/ig.png"
import tl from "../assets/tl.png"

function Footer() {
  return (
    <div className="footer">
      <div className="footer-content">
        <div className="footer-top">
            <div className="footer-item footer-item-1">
                <img src={logo} alt="logo" className="logo-footer" />
            </div>
            <div className='menu-footer-link'>
                <div className="footer-item menu-footer-link-margin-bottom-20">
                    <p>WHO WE ARE</p>
                    <ul>
                        <li><Link to="/representative">Our representative</Link></li>
                        <li><Link to="/text_founding">Foundational texts</Link></li>
                        <li><Link to="/your_federation">Subsidiary</Link></li>
                    </ul>
                </div>
                <div className="footer-item menu-footer-link-margin-bottom-20">
                    <p>OUR IDEAS</p>
                    <ul>
                        <li><Link to="/our_ideas">Our Project for Chechnya</Link></li>
                        <li><Link to="/idea_more?w=1">Democracy</Link></li>
                        <li><Link to="/idea_more?w=2">De-occupancy</Link></li>
                        <li><Link to="/idea_more?w=3">1992 Constitution</Link></li>
                        <li><Link to="/idea_more?w=4">Protectorate</Link></li>
                    </ul>
                </div>
                <div className="footer-item">
                    <p>LEGAL</p>
                    <ul>
                        <li><Link to="/contact">Contact Us</Link></li>
                        <li><Link to="/privacy">Legal Information</Link></li>
                    </ul>
                </div>
            </div>
        </div>
        <hr />
        <div className="footer-bottom">
            <p>© 2023 CHDP, All Rights Reserved.</p>
            <div>
                <a href='#'><img src={tw} alt="tw" className="tw-footer" /></a>
                <a href='#'><img src={fb} alt="fb" className="fb-footer" /></a>
                <a href='#'><img src={ig} alt="ig" className="ig-footer" /></a>
                <a href='#'><img src={tl} alt="tl" className="tl-footer" /></a>
            </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;