import '../styles/representative.css';
import Header from "../components/header";
import Footer from "../components/footer"
import president from "../assets/president.jpeg"

function Representative() {
  return (
    <div>
        <Header />
        <div className='body who-we-are-background'>
            <div className='representative'>
                <h1>Our representative Huseyn ISKHANOV</h1>
                <img src={president} />
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas sed mattis nisl, euismod consectetur augue. Curabitur urna sem, ullamcorper non sapien ac, sollicitudin tristique mi. Maecenas vitae justo dui. Suspendisse potenti. Sed malesuada eleifend placerat. Aenean tempus eros ante, eget vulputate sapien aliquet a. Curabitur nec venenatis justo. Proin pulvinar magna vulputate lorem tincidunt, vitae sagittis ipsum ultricies. Proin vulputate quis lectus id faucibus. Nam in enim ac lorem hendrerit cursus. Curabitur dictum tellus eu elit pulvinar ornare. Vivamus non ex eleifend nibh molestie dapibus viverra in metus.<br /><br />Aenean ac molestie est. Mauris at egestas elit. Quisque cursus leo ac diam sollicitudin placerat. Fusce quis ipsum pellentesque neque cursus feugiat auctor quis massa. Donec volutpat, lorem tincidunt varius pharetra, eros sem varius neque, eget interdum eros orci non mi. Fusce faucibus molestie convallis. Nulla molestie faucibus erat ut efficitur. Curabitur sagittis metus id sapien finibus mattis quis at nunc. In at nulla sed turpis aliquam auctor ac et ligula. Quisque cursus sollicitudin dui, vitae ultricies ligula feugiat a. Donec sed magna ac dolor vestibulum ornare. Sed neque mi, rhoncus lobortis urna sed, laoreet cursus tortor. Pellentesque sit amet sem eget leo aliquet elementum.</p>
            </div>
            <Footer />
        </div>
    </div>
  );
}

export default Representative;