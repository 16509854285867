import "../../styles/home.css"
import { useNavigate } from "react-router-dom";

function MainView() {
  const modalSubscribe = () => {
    const subscriber = document.getElementsByClassName("subscriber");
    document.body.style.overflow = "hidden"
    subscriber[0].style.display = "block"
  }

  return (
    <div className="main-view" data-aos="fade-up">
      <div className="main-view-content">
        <p className="small-main-view animate__fadeInUp">The time for action is now</p>
        <h1>Uniting and building the democratic Chechnya of tomorrow</h1>
        <p className="description-main-view animate__fadeInUp">After all, this is the duty of all Chechens, so don't wait any longer, come and build our free and democratic country.</p>
      </div>
    </div>
  );
}

export default MainView;