import "../../styles/home.css"
import { useNavigate } from "react-router-dom";

function JoinParty() {
  const modalSubscribe = () => {
    const subscriber = document.getElementsByClassName("subscriber");
    document.body.style.overflow = "hidden"
    subscriber[0].style.display = "block"
  }

  return (
    <div className="join-party-view" data-aos="fade-up">
      <div className="join-party-view-content">
        <h2>Uniting and building the democratic Chechnya of tomorrow</h2>
        <p className="description-join-party-view">After all, this is the duty of all Chechens, so don't wait any longer, come and build our free and democratic country.</p>
        <button className='join-button-join-party' onClick={modalSubscribe}>Joining the movement <img src={require('../../assets/right_black.svg').default} alt="right_arrow" /></button>
      </div>
    </div>
  );
}

export default JoinParty;