import {Link} from 'react-router-dom';
import "../../styles/home.css"
import { useEffect, useState } from 'react';

function ReadThis() {
    const [status, setStatus] = useState(false);
    const [link, setLink] = useState(["", "", ""]);
    const [title, setTitle] = useState(["", "", ""]);
    const [thubmnail, setThubmnail] = useState(["", "", ""]);

    // const seeMoreVideos = () => {
    //     window.location.replace('https://codefrontend.com');
    // }

    const fetchVideo = async () => {
        if (status === false) {
            setStatus(true)

            await fetch("https://www.googleapis.com/youtube/v3/search?key=AIzaSyA26caTDePw0Tc0cvJ_LJ69fAk1c1gVA24&channelId=UC9bS8m7ZykzYOJ-6LSPGlaw&part=snippet,id&order=date&maxResults=3")
            .then((response) =>response.json())
            .then((responseJson) => {
                const youtube = responseJson.items.map(obj => "https://www.youtube.com/embed/"+ obj.id.videoId);
                const title = responseJson.items.map(obj => obj.snippet.title);
                const imgs = responseJson.items.map(obj => obj.snippet.thumbnails.high);

                setLink(youtube);
                setTitle(title)
                setThubmnail(imgs);

                const class1 = document.getElementsByClassName("background-color-5FC8EF");
                const class2 = document.getElementsByClassName("background-color-F7CB94");
                const class3 = document.getElementsByClassName("background-color-F67665");

                class1[0].style.backgroundImage = "url(" + imgs[0].url + ")";
                class2[0].style.backgroundImage = "url(" + imgs[1].url + ")";
                class3[0].style.backgroundImage = "url(" + imgs[2].url + ")";
            })
        }
    }

    useEffect(() => {
        fetchVideo();
    });

  return (
    <div className="read-this-view" data-aos="fade-up">
      <div className="read-this-view-content">
        <h2><span className="color-blue-radiant animate__fadeInUp">Watch</span> our latest videos</h2>
        <ul>
            <li>
                <Link to={link[0]} target="_blank">
                    <div className='read-frame'>
                        <div className='read-frame-image background-color-5FC8EF'>
                            <div className='player-style-back'></div>
                            <div className='player-style-font'></div>
                            <img src={require('../../assets/play.svg').default} alt="play" />
                        </div>
                        <p>{title[0]}</p>
                    </div>
                </Link>
            </li>
            <li>
                <Link to={link[1]} target="_blank">
                    <div>
                        <div className='read-frame'>
                            <div className='read-frame-image background-color-F7CB94'>
                                <div className='player-style-back'></div>
                                <div className='player-style-font'></div>
                                <img src={require('../../assets/play.svg').default} alt="play" />
                            </div>
                            <p>{title[1]}</p>
                        </div>
                    </div>
                </Link>
            </li>
            <li>
                <Link to={link[2]} target="_blank">
                    <div>
                        <div className='read-frame'>
                            <div className='read-frame-image background-color-F67665'>
                                <div className='player-style-back'></div>
                                <div className='player-style-font'></div>
                                <img src={require('../../assets/play.svg').default} alt="play" />
                            </div>
                            <p>{title[2]}</p>
                        </div>
                    </div>
                </Link>
            </li>
        </ul>
        <Link to={{pathname: "https://www.youtube.com/@chechendemocraticparty/featured"}} target="_blank">
            <button>See more videos <img src={require('../../assets/right_bleu.svg').default} alt="right_arrow" /></button>
        </Link>
      </div>
    </div>
  );
}

export default ReadThis;