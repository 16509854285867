import { useEffect, useState } from 'react';
import {isMobile} from 'react-device-detect';
import "../../styles/home.css"
import dlAppPopUp from "../../assets/dl_app_pop_up.png"
import dlAppPopUpMobile from "../../assets/dl_app_mobile.jpg"
import { initializeApp } from "firebase/app";
import Parser from 'html-react-parser';
import { getFirestore, getDocs, collection, query, orderBy, limit, where } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyCtSIbThgUlt9dr6Wcw9rAT8q4Mzn6o5X4",
  authDomain: "chdpqa.firebaseapp.com",
  projectId: "chdpqa",
  storageBucket: "chdpqa.appspot.com",
  messagingSenderId: "229005004837",
  appId: "1:229005004837:web:f3356ca134c4c4a28a2fcc",
  measurementId: "G-5YT3FSWCVG"
};

function DlOurApp() {
  const [status, setStatus] = useState(false);
  const [data, setData] = useState({
    "chdp_question": "",
    "chdp_yes_path": '',
    "chdp_no_path": '',
    "display_yes": 0,
    "display_no": 0,
  });
  const [isOpenDesktop, setIsOpenDesktop] = useState(false);
  const [isOpenMobile, setIsOpenMobile] = useState(false);

  const dlAppClick = () => {
    const popup = document.getElementsByClassName("pop-up-answer-question-mobile");

    setIsOpenMobile(!isOpenMobile);

    if (isOpenMobile === false) {
      popup[0].style.display = "block";
    } else {
      popup[0].style.display = "none";
    }
  }

  const buttonClick = () => {
    if (isMobile) {
      const popup = document.getElementsByClassName("pop-up-answer-question-mobile");

      setIsOpenMobile(!isOpenMobile);

      if (isOpenMobile === false) {
        popup[0].style.display = "block";
      } else {
        popup[0].style.display = "none";
      }
    } else {
      const popup = document.getElementsByClassName("pop-up-answer-question-desktop");

      setIsOpenDesktop(!isOpenDesktop);

      if (isOpenDesktop === false) {
        popup[0].style.display = "block";
      } else {
        popup[0].style.display = "none";
      }
    }
  }

  const getWeekQuestion = async () => {
    if (status === false) {
      setStatus(true)
      const app = initializeApp(firebaseConfig);
      const db = getFirestore(app);

      const collection_ref_needs = collection(db, "chdp_needs")
      const q_needs = query(collection_ref_needs, where("chdp_type", "==", "COUNT_USERS"))
      const q_limit_needs = query(q_needs, limit(1));
      const doc_refs_needs = await getDocs(q_limit_needs);

      const collection_ref_week_question = collection(db, "chdp_week_question")
      const q_week_question = query(collection_ref_week_question, orderBy("chdp_date", "desc"))
      const q_limit_week_question = query(q_week_question, limit(1));
      const doc_refs_week_question = await getDocs(q_limit_week_question);

      setData({
        "chdp_question": doc_refs_week_question.docs[0].data().chdp_question,
        "chdp_yes_path": '<path className="circle" stroke-dasharray="' + ((doc_refs_week_question.docs[0].data().chdp_yes * 100) / doc_refs_needs.docs[0].data().chdp_count).toFixed(0).toString() + ', 100" d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831" />',
        "chdp_no_path": '<path className="circle" stroke-dasharray="' + ((doc_refs_week_question.docs[0].data().chdp_no * 100) / doc_refs_needs.docs[0].data().chdp_count).toFixed(0).toString() + ', 100" d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831" />',
        "display_yes": ((doc_refs_week_question.docs[0].data().chdp_yes * 100) / doc_refs_needs.docs[0].data().chdp_count).toFixed(0),
        "display_no": ((doc_refs_week_question.docs[0].data().chdp_no * 100) / doc_refs_needs.docs[0].data().chdp_count).toFixed(0),
      });

      console.log(((doc_refs_week_question.docs[0].data().chdp_yes * 100) / doc_refs_needs.docs[0].data().chdp_count).toFixed(0).toString() + ", 100");
      console.log(data.chdp_no);
    }
  }

  useEffect(() => {
    getWeekQuestion()
  })

  return (
    <div className="week-question-view">
      <div className="week-question-view-content" data-aos="fade-up">
        <div className='week-question-left-items'>
          <h2><span className="color-blue-radiant">Question of the week.</span></h2>
          <p>{data.chdp_question}</p>
          <button className="button-answer-question" onClick={buttonClick}>Answer the question <img src={require('../../assets/right_white.svg').default} alt="right_arrow" /></button>
        </div>
        <div className='week-question-right-items'>
            <div className="circular-chart chart-a">
                <div className="circular-chart-circle">
                  <svg viewBox="-1 -1 40 40" className="circular-chart-child blue">
                      <path className="circle-bg"
                          d="M18 2.0845
                          a 15.9155 15.9155 0 0 1 0 31.831
                          a 15.9155 15.9155 0 0 1 0 -31.831"
                      />
                      {data.chdp_yes_path === "" ? "" : Parser(data.chdp_yes_path)}
                  </svg>
                  <p>{data.display_yes}%</p>
                </div>
                <p>YES</p>
            </div>
            <div className="circular-chart">
              <div className="circular-chart-circle">
                  <svg viewBox="-1 -1 40 40" className="circular-chart-child blue">
                      <path className="circle-bg"
                          d="M18 2.0845
                          a 15.9155 15.9155 0 0 1 0 31.831
                          a 15.9155 15.9155 0 0 1 0 -31.831"
                      />
                      {data.chdp_no_path === "" ? "" : Parser(data.chdp_no_path)}
                  </svg>
                  <p>{data.display_no}%</p>
                </div>
                <p>NO</p>
            </div>
        </div>
      </div>
      <div className='pop-up-answer-question-desktop'>
        <div className='pop-up-answer-question-desktop-content'>
          <img src={dlAppPopUp} alt="dl_app_pop_up" />
          <h3>Download our app</h3>
          <p>To be able to answer our questions each week, please log in and start answering again or download our app directly.</p>
          <button className='button-pop-up-answer-question-destkop' onClick={buttonClick}>I understand</button>
        </div>
      </div>
      <div className='pop-up-answer-question-mobile'>
        <div className='pop-up-answer-question-mobile-content'>
          <img src={dlAppPopUpMobile} alt="dl_app_pop_up" />
          <h3>Download App</h3>
          <p>By downloading the app, you can answer our questions each week and also ask your own specific questions.</p>
          <button className='button-pop-up-answer-question-mobile' onClick={dlAppClick}>I'm downloading</button>
          <button className='button-pop-up-answer-question-2-mobile' onClick={buttonClick}>No, thank you</button>
        </div>
      </div>
    </div>
  );
}

export default DlOurApp;