import '../../styles/your_federation.css';

function Grid({city, country, image}) {
    return (
        <div className='grid'>
            <div className='grid-shadow'>
                <div className='shadow-frame shadow-frame-1'></div>
                <div className='shadow-frame shadow-frame-2'></div>
            </div>
            <div className='stack'>
                <img src={image} alt='image' />
                <h3>{city}, {country}</h3>
            </div>
        </div>
    );
}

export default Grid;