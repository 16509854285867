import '../styles/text-founding.css';
import Header from "../components/header";
import Footer from "../components/footer"
import Grid from "../components/text-founding/container"
import File from "../assets/file.png"
import File1 from "../assets/file_1.png"

function TextFoundig() {
    return (
        <div>
            <Header />
            <div className='body text-founding-background'>
            <div className='text-founding-view'>
                <div className='text-founding-view-content'>
                    <h1><span className="color-blue-radiant">Founding</span> texts</h1>
                    <p className="description-text-founding-view">Find all our founding texts of our democratic party</p>
                    <div className='files-link-text-founding'>
                        <Grid
                            image={File1}
                            link={"http://localhost:3000/files/text-fondateur.pdf"}
                            name={"The Charter of the Chechen Democratic Party"}
                            color={"transparent"}
                            marginBottom={30}
                            colorText={"black"}
                            statusFile={false}
                            text={"The Charter of the Chechen Democratic Party is a founding document that defines the principles and values of the party. It was written to guide the actions of the party in its mission to promote democracy and human rights in Chechnya."}
                        />
                        <Grid
                            image={File}
                            link={"http://localhost:3000/files/text-fondateur.pdf"}
                            name={"Rules of the movement procedure"}
                            color={"transparent"}
                            marginBottom={0}
                            colorText={"black"}
                            statusFile={true}
                            text={"The rules of motion procedure are a set of guidelines set forth to guide the members of a political party or organization in their collective decision making process."}
                        />
                    </div>
                </div>
            </div>
            <Footer />
            </div>
        </div>
    )
}

export default TextFoundig;