import '../styles/your_federation.css';
import Header from "../components/header";
import Footer from "../components/footer"
import Grid from "../components/your_federation/container";

import vn from "../assets/vienna.jpg";
import ps from "../assets/paris.jpg";
import st from "../assets/strasbourg.jpg";
import co from "../assets/cologne.jpg";

function YourFederation() {
  return (
    <div>
        <Header />
        <div className='body your-federation-background'>
            <div className='your-federation-view'>
                <div className='your-federation-view-content'>
                    <h1>Your <span className="color-blue-radiant">Subsidiary</span></h1>
                    <p className="description-your-federation-view">Our members for the democratic part are all over the world, do not wait any longer, your help is very important for us.</p>
                </div>
                <h2>Austria</h2>
                <div className='your-federation-view-content-grid' data-aos="fade-up">
                    <Grid
                        city="Vienna"
                        country="Austria"
                        image={vn}
                    />
                </div>
                <h2>France</h2>
                <div className='your-federation-view-content-grid' data-aos="fade-up">
                    <Grid
                        city="Paris"
                        country="France"
                        image={ps}
                    />
                    <Grid
                        city="Strasbourg"
                        country="France"
                        image={st}
                    />
                    <Grid
                        city="Strasbourg"
                        country="France"
                        image={st}
                    />
                </div>
                <h2>Germany</h2>
                <div className='your-federation-view-content-grid' data-aos="fade-up">
                    <Grid
                        city="Cologne"
                        country="Germany"
                        image={co}
                    />
                </div>
            </div>
            <Footer />
        </div>
    </div>
  );
}

export default YourFederation;